import { Box, chakra } from "@chakra-ui/react";
import type { HTMLChakraProps, ChakraComponent } from "@chakra-ui/react";
import { Image as JssImage, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import type { ImageField } from "@sitecore-jss/sitecore-jss-nextjs";

import NextImage from "next/image";
import type { ImageProps } from "next/image";

type CleanNextImageProps = Omit<
    ImageProps,
    | "layout"
    | "alt"
    | "objectFit"
    | "objectPosition"
    | "lazyBoundary"
    | "lazyRoot"
    | "src"
    | "sizes"
>;

export type NextImageProps = CleanNextImageProps &
    Omit<HTMLChakraProps<"img">, keyof ImageProps> & {
        // we are disabling static image imports, so we remove StaticImport as a type for src
        sizes?: string[] | string;
        width?: number | string;
        height?: number | string;
    };

export const ChakraNextImage: ChakraComponent<"img", NextImageProps> = chakra(NextImage, {
    shouldForwardProp: (prop) =>
        [
            "src",
            "alt",
            "width",
            "height",
            "fill",
            "loader",
            "quality",
            "priority",
            "loading",
            "placeholder",
            "blurDataURL",
            "unoptimized",
            "onLoadingComplete",
            "sizes",
        ].includes(prop),
});

interface SCImageProps extends NextImageProps {
    imageField?: ImageField;
}

const SCImage = ({ imageField, ...props }: SCImageProps) => {
    const {
        sitecoreContext: { pageEditing, pageState },
    } = useSitecoreContext();

    if (!imageField) return null;

    if (pageState !== "preview" && !pageEditing && imageField?.value?.src) {
        return (
            <ChakraNextImage
                src={imageField?.value?.src}
                alt={imageField?.value?.alt?.toString() ?? ""}
                {...props}
            />
        );
    } else if (pageState === "preview" || pageEditing) {
        return (
            <Box
                sx={{
                    ".sc-image-wrapper": { width: "full", height: "full" },
                    img: { ...props.sx, width: "full", height: "full" },
                }}
            >
                <JssImage field={imageField} />
            </Box>
        );
    }

    return null;
};

export default SCImage;
